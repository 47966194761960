exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-wp-post-slug-js": () => import("./../../../src/pages/blog/{wpPost.slug}.js" /* webpackChunkName: "component---src-pages-blog-wp-post-slug-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-video-photo-portfolio-js": () => import("./../../../src/pages/video-photo-portfolio.js" /* webpackChunkName: "component---src-pages-video-photo-portfolio-js" */),
  "component---src-pages-web-dev-portfolio-js": () => import("./../../../src/pages/web-dev-portfolio.js" /* webpackChunkName: "component---src-pages-web-dev-portfolio-js" */)
}

